// Step 1: Import your component
import * as React from 'react'
import { withAuthenticator} from '@aws-amplify/ui-react';
import { navigate } from "gatsby"
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../util/scoop-Setting';
import Layout from '../components/layout'
import { Link } from 'gatsby' 
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut, AmplifyFacebookButton, AmplifyGoogleButton } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);


// Step 2: Define your component
const AboutPage = () => {
  
  const federated = {
    googleClientId: '771418425547-ls9e2g6p16dphmtsen4mqlflthv03e02.apps.googleusercontent.com',//, Enter your googleClientId here
    facebookAppId: '1189311428253787' // Enter your facebookAppId here
    //amazonClientId: '' // Enter your amazonClientId here
};

  return (
 <> 
	  <AmplifyAuthenticator usernameAlias="email" >
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
		   {
            type: "name",
            label: "name",
            placeholder: "First Name",
            inputProps: { required: true }, 
          },
          {
            type: "email",
            label: "Email Address", 
            placeholder: "Email Addres",
            inputProps: { required: true, autocomplete: "username" },
          },
          {
            type: "password",
            label: "Custom Password Label",
            placeholder: "Custom password placeholder",
            inputProps: { required: true, autocomplete: "new-password" },
          },
          
        ]} 
      />
      <AmplifySignIn slot="sign-in" usernameAlias="email" federated={federated}
	       formFields={[
		  {
            type: "email",
            label: "Email Address", 
            placeholder: "Email Addres",
            inputProps: { required: true, autocomplete: "username" },
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Custom password placeholder",
            inputProps: { required: true, autocomplete: "new-password" },
          }
	  ]}>
	   <AmplifyFacebookButton
        slot="federated-buttons"
        onClick={() =>
          Auth.federatedSignIn({
            provider: "Facebook",
          })
        }/>
		  <AmplifyGoogleButton
        slot="federated-buttons"
        onClick={() =>
          Auth.federatedSignIn({
            provider: "Google",
          })
        }/>
	  </AmplifySignIn>
	 
	  <div>
	   
	         Redirect
			 <Link to="/Account" className="shaun">
              Account
            </Link>
	   </div>
    </AmplifyAuthenticator>
    
  
	
	
	</>
  )
}
// Step 3: Export your component
export default AboutPage

/*
  <AmplifyFacebookButton
        slot="federated-buttons"
        onClick={() =>
          Auth.federatedSignIn({
            provider: "Facebook",
          })
        }
		/>*/